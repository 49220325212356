<template>
  <div class="no-result-dashboard mx-auto">

    <div class="waiting-result">
      <div>
        <h4>Exciting New Feature Coming Soon! 🚀</h4>
        <h6>We are thrilled to announce that a brand-new feature is on its way to enhance your experience with us!</h6>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
}
</script>

<style lang="scss" scoped>
</style>
